import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, P, Ul } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj14 from "../imgObjs/imgObj14"

import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Akupunktur"
      keywords={[`Hebamme`, `Frankfurt`, `Beratung`, `Geesche Martin`]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj14} />
    </Figure>
    <Section>
      <Flex flexDirection={"row-reverse"}>
        <Box width={[1, 1, 1 / 2]}>
          <P textAlign={"right"}>
            <em>„An Akupunktur muss man nicht glauben, damit sie hilft.“</em>
            <br />— Geesche Martin
          </P>
        </Box>
      </Flex>
      <H1>Akupunktur</H1>

      <H3>Akupunktur in der Schwangerschaft und im Wochenbett</H3>
      <P>
        Dass Akupunktur wirklich hilft, stellen alle diejenigen fest, die die
        Kraft und Wirksamkeit der Akupunktur einmal selbst erfahren haben. Die
        meisten Menschen empfinden die Akupunkturbehandlung und ihre Nachwirkung
        als sehr entspannend und angenehm. Der sachte Einstich der Nadeln ist
        zudem viel unspektakulärer als sie ihn sich vorgestellt haben.
      </P>
      <H3>Welche Beschwerden kann Akupunktur lindern?</H3>
      <P>
        Mit der Akupunktur lassen sich vielseitige Beschwerden sehr wirksam und
        dauerhaft behandeln. In der folgenden Übersicht finden Sie die
        häufigsten, in der Schwangerschaft und im Wochenbett auftretenden
        Beschwerden:
      </P>
      <Ul>
        <li>Rückenschmerzen, Muskelverspannungen</li>
        <li>Übelkeit, Erbrechen und Sodbrennen</li>
        <li>Wassereinlagerungen</li>
        <li>Verdauungsbeschwerden</li>
        <li>Schlafstörungen und Erschöpfungszustände</li>
        <li>Stimmungsschwankungen und Unruhezustände</li>
        <li>Migräne und Kopfschmerzen</li>
        <li>Gefühlsstörungen im Bereich der Hand (Karpaltunnelsyndrom)</li>
        <li>Hämorrhoiden und Varizen</li>
        <li>Schmerzen im Beckenbereich und Schambein</li>
        <li>Neurodermitis, Allergien und Asthma</li>
        <li>Infektanfälligkeit</li>
        <li>Nikotinabhängigkeit</li>
        <li>Narbenprobleme (z.B. nach Kaiserschnitt)</li>
        <li>Stillprobleme (Milchmangel, Milchstau)</li>
      </Ul>

      <H3>Wie wirkt Akupunktur und was bewirkt sie?</H3>
      <P>
        In der Traditionellen Chinesischen Medizin (TCM) gibt es die Vorstellung
        von Leitbahnen im Körper, durch die die Lebensenergie fließt. Diese
        sogenannten Meridiane durchziehen den Körper ähnlich den Blutgefäßen. An
        gewissen Punkten können sie mit Nädelchen hilfreich stimuliert werden.
        Diese Art der Stimulation aus der TCM nennt man Akupunktur.
      </P>
      <P>
        Unsere Gesundheit ist nach den Vorstellungen der TCM verbunden mit einem
        freien Fluss der Lebensenergie in den Meridianen. Wird er gestört, kann
        es zu einem Stau und in seiner Folge zu Beschwerden im ganzen Körper
        kommen.
      </P>
      <P>
        Das Setzen der Akupunkturnadeln soll den Körper anregen, den Stau selbst
        zu beheben. So kommt der Fluss der Lebensenergie wieder in Bewegung -
        die Beschwerden lindern sich.
      </P>
    </Section>
  </Layout>
)

export default IndexPage
